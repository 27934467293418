<template>
  <div class="login-wrapper">
    <div class="login-img" />
    <div class="login-logo"></div>
    <div class="login-form">
      <div class="title">
        {{ $t('login.title') }}
        <!--        <img :src="logoUrl" />-->
      </div>
      <transition name="fade-transform" mode="out-in">
        <a-form
          v-if="show === 'login'"
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          :wrapper-col="{ span: 24 }"
          :validate-trigger="['change', 'blur']"
          :colon="false"
          hide-required-mark
        >
          <a-form-item name="account">
            <a-input
              allow-clear
              autocomplete="off"
              v-model:value="loginForm.account"
              :placeholder="$t('login.account.placeholder')"
              @keyup.enter="handleLogin"
            />
          </a-form-item>
          <a-form-item name="password">
            <a-input-password
              v-model:value="loginForm.password"
              :placeholder="$t('login.password')"
              @keyup.enter="handleLogin"
              autocomplete="new-password"
            />
          </a-form-item>
          <!--          <a-form-item name="code">-->
          <!--            <a-input-->
          <!--              autocomplete="off"-->
          <!--              v-model:value="loginForm.code"-->
          <!--              :placeholder="$t('login.code')"-->
          <!--              @keyup.enter="handleLogin"-->
          <!--            />-->
          <!--            <div class="code-img">-->
          <!--              <a-spin :spinning="spinning" size="small">-->
          <!--                <a-image-->
          <!--                  :height="36"-->
          <!--                  :preview="false"-->
          <!--                  :src="codeSrc"-->
          <!--                  :fallback="errorImg"-->
          <!--                  @click="getCaptcha"-->
          <!--                />-->
          <!--              </a-spin>-->
          <!--            </div>-->
          <!--          </a-form-item>-->
        </a-form>
        <a-form
          v-else-if="show === 'forget'"
          ref="forgetForm"
          :model="forgetForm"
          :rules="forgetRules"
          :wrapper-col="{ span: 24 }"
          :validate-trigger="['change', 'blur']"
          :colon="false"
          hide-required-mark
        >
          <a-form-item name="account">
            <a-input
              allow-clear
              autocomplete="off"
              v-model:value="forgetForm.account"
              :placeholder="$t('login.account.placeholder')"
            />
          </a-form-item>
          <!--          <a-form-item name="code">-->
          <!--            <a-input-->
          <!--              autocomplete="off"-->
          <!--              v-model:value="forgetForm.code"-->
          <!--              :placeholder="$t('login.code')"-->
          <!--            />-->
          <!--            <a-button-->
          <!--              class="code-img"-->
          <!--              type="primary"-->
          <!--              :disabled="btn.disabled"-->
          <!--              ghost-->
          <!--              @click="handleGetCode"-->
          <!--            >-->
          <!--              {{ btn.title }}-->
          <!--            </a-button>-->
          <!--          </a-form-item>-->
        </a-form>
        <div v-else-if="['password', 'forgetPassword'].includes(show)">
          <a-alert
            show-icon
            message="请设置新密码"
            type="info"
            style="margin-bottom: 10px"
          />
          <a-form
            ref="passwordForm"
            :model="passwordForm"
            :rules="passwordRules"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 19 }"
            :validate-trigger="['change', 'blur']"
            :colon="false"
            hide-required-mark
          >
            <a-form-item v-if="show === 'password'" name="oldPassword">
              <a-input-password
                v-model:value="passwordForm.account"
                placeholder="请输入旧密码"
              />
            </a-form-item>
            <a-form-item name="password">
              <a-input-password
                v-model:value="passwordForm.password"
                placeholder="请输入8-20位新密码"
              />
              <span class="Prompt-font"
                >长度为8-20位,必须同时含有字母(大小写)、数字和符号</span
              >
            </a-form-item>
            <a-form-item name="confirmPassword">
              <a-input-password
                v-model:value="passwordForm.confirmPassword"
                placeholder="请输入确认密码"
              />
            </a-form-item>
          </a-form>
        </div>
        <div v-else-if="show === 'reLogin'">
          <a-alert
            show-icon
            message="修改成功，请重新登录"
            type="success"
            style="margin-bottom: 10px"
          />
        </div>
      </transition>
      <a-button
        class="btn"
        :loading="loading"
        type="primary"
        block
        @click="handleClick"
      >
        {{ btnText[show] }}
      </a-button>
      <!--      <div class="forgetPassword ta-right">-->
      <!--        <a class="link" @click="handleClickByLink">-->
      <!--          {{ linkText[show] }}-->
      <!--        </a>-->
      <!--      </div>-->
    </div>

    <div class="copyright">{{ $t('login.copyright') }}</div>
    <div class="iss-logo">
      <img src="../../assets/images/login-logo.png" alt="" />
    </div>
    <!--    <div class="tips-cover">-->
    <!--      <div class="text">-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { ref } from 'vue';
// Image, Spin
import { Alert, Form } from 'ant-design-vue';
// import { randoms } from '@/utils';
import { validMobile, validEmail } from '@/utils/validation';
import errorImg from '@/assets/images/error.png';
import loginApi from '@/api/login.js';
import personalApi from '@/api/personal';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    AAlert: Alert,
    // AImage: Image,
    // ASpin: Spin,
  },
  data() {
    const required = { required: true, whitespace: true, message: '不能为空' };
    const validAccount = (rule, value) =>
      value.trim()
        ? validMobile(value) || validEmail(value)
          ? Promise.resolve()
          : Promise.reject('请输入合法的手机号或者邮箱')
        : Promise.reject('不能为空');
    const visibilityToggle = ref(true);

    return {
      visibilityToggle,
      logoUrl: process.env.VUE_APP_PROJECT_LOGIN_LOGO,
      errorImg,
      btnText: {
        login: '立即登录',
        forget: '下一步',
        forgetPassword: '完成',
        password: '完成',
        reLogin: '重新登录',
      },
      linkText: {
        login: '忘记密码',
        forget: '登录',
        forgetPassword: '登录',
      },
      show: 'login',
      loading: false,
      spinning: false,
      codeSrc: '',
      // 首页登陆
      loginForm: { account: '', password: '', key: '' },
      loginRules: {
        account: { validator: validAccount },
        password: required,
        // code: required,
      },
      forgetForm: { account: '' },
      forgetRules: {
        account: { validator: validAccount },
        // code: required,
      },
      // 密码校验
      passwordForm: { oldPassword: '', password: '', confirmPassword: '' },
      passwordRules: {
        oldPassword: required,
        password: [
          required,
          {
            pattern:
              /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/,
            message: '请输入正确的密码格式',
          },
        ],
        confirmPassword: {
          validator: (rule, value) => {
            return value.trim()
              ? value === this.passwordForm.password
                ? Promise.resolve()
                : Promise.reject('两次输入密码不匹配')
              : Promise.reject('不能为空');
          },
          trigger: 'blur',
        },
      },
      btn: {
        disabled: false,
        title: '获取验证码',
      },
      cache: {},
    };
  },
  created() {
    // this.getCaptcha();
  },
  methods: {
    // getCaptcha() {
    //   this.spinning = true;
    //   this.loginForm.key = randoms(24, 16);
    //   loginApi
    //     .getCaptcha(this.loginForm.key)
    //     .then(data => (this.codeSrc = data))
    //     .catch(err => {
    //       console.log(err);
    //       this.getCaptcha();
    //     })
    //     .finally(() => (this.spinning = false));
    // },
    handleClick() {
      switch (this.show) {
        case 'login':
          this.handleLogin();
          break;
        case 'forget':
          this.handleNext();
          break;
        case 'password':
        case 'forgetPassword':
          this.handlePassword();
          break;
        // case 'reLogin':
        //   this.handleClickByLink();
        //   break;
        default:
          break;
      }
    },
    // 忘记密码按钮
    // handleClickByLink() {
    // this.show==='forgetPassword' && this.$refs.passwordForm.clearValidate()
    // this.show = this.show === 'login' ? 'forget' : 'login';
    // },
    handleLogin() {
      this.$refs.loginForm.validate().then(() => {
        this.loading = true;
        loginApi
          .login(this.loginForm)
          .then(res => {
            // debugger;
            console.log(res);
            this.$store.commit('account/setTenant', '0000');
            this.$store.commit('account/setToken', res.token);
            this.$store.commit('account/setUser', {
              ...res,
              roleCode: 'SUPER_ADMIN',
            });
            this.$store.commit('account/setPermissions', []);
            this.$message.success('登录成功');
            this.$router.push('/');
          })
          .catch(() => {
            // this.loginForm.code = '';
            // this.getCaptcha();
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false;
            }, 1500);
          });
      });
    },
    handleGetCode() {
      this.$refs.forgetForm.validateFields('account').then(() => {
        loginApi.getTenant({ account: this.forgetForm.account }).then(data => {
          if (data) {
            this.$store.commit('account/setTenant', data);
            this.btn = { disabled: true, title: '重新获取(60s)' };
            let count = 60;
            let timer = setInterval(() => {
              if (count === 1) {
                this.btn = { disabled: false, title: '获取验证码' };
                clearInterval(timer);
                timer = null;
              } else {
                count--;
                this.btn.title = `重新获取(${count}s)`;
              }
            }, 1000);
            loginApi.getCode(this.forgetForm.account).then(() => {
              this.$message.success('验证码已发送，请注意查收');
            });
          } else {
            this.$message.error('该账号不存在企业信息，请重新输入');
          }
        });
      });
    },
    handleNext() {
      this.$refs.forgetForm.validate().then(() => {
        this.loading = true;
        loginApi
          .verifyCode(this.forgetForm)
          .then(data => {
            if (data) {
              loginApi
                .verifyAccount(this.forgetForm.account)
                .then(d => {
                  if (d) {
                    this.cache.user = { userId: d.id };
                    this.show = 'forgetPassword';
                  } else {
                    this.$message.error('账号不存在，请重新输入');
                  }
                })
                .finally(() => {
                  this.loading = false;
                });
            } else {
              this.loading = false;
              this.$message.error('验证失败，请重新获取验证码');
            }
          })
          .catch(() => (this.loading = false));
      });
    },
    // handleLoginCount () {
    //   let count = 3;
    //   this.$message.success(`修改密码成功,将在${count}秒后跳转至登陆页面`);
    //   let closeSetInterval = setInterval(() => {
    //     count--;
    //     if (count !== 0) {
    //       this.$message.success(`修改密码成功,将在${count}秒后跳转至登陆页面`);
    //     } else {
    //       // 清除定时器
    //       clearInterval(closeSetInterval);
    //       localStorage.clear();
    //       this.show = 'login';
    //     }
    //   }, 1000);
    // },
    // 设置完新密码的按钮
    handlePassword() {
      let temp = ['password', 'confirmPassword'];
      if (this.show === 'password') {
        temp.push('oldPassword');
      }
      this.$refs.passwordForm.validateFields(temp).then(() => {
        this.loading = true;
        personalApi
          .updatePassword({
            ...this.passwordForm,
            id: this.cache.user.userId,
          })
          .then(() => {
            localStorage.clear();
            // this.handleLoginCount();
            this.show = 'reLogin';
          })
          .finally(() => (this.loading = false));
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login-wrapper {
  position: relative;
  display: flex;
  height: 100vh;
  width: 100%;
  .login-logo {
    flex: 1;
    background: linear-gradient(90deg, #7e98ff 0%, #5979f8 100%);
    position: relative;
  }
  .copyright {
    color: #1d2129;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    bottom: 20px;
  }
  .iss-logo {
    width: 12%;
    position: absolute;
    left: 5%;
    top: 5%;
    img {
      width: 100%;
    }
  }
  .login-img {
    min-width: 960px;
    flex: 3;
    background: url('../../assets/images/login.png') no-repeat center;
    background-size: 100% 100%;
    background-position: right;
  }
  .login-form {
    min-width: 320px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12%;
    max-width: 80%;
    width: 380px;
    height: 480px;
    padding: 80px 40px;
    background-color: #ffffffcc;
    backdrop-filter: blur(10px);
    box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 2px solid #ffffff;
    :deep(.ant-form label) {
      font-size: 16px;
    }
    .title {
      font-weight: bold;
      margin-bottom: 2vw;
      font-size: 2rem;
      img {
        width: 60%;
        height: auto;
      }
    }
    :deep(.ant-form-item) {
      margin-bottom: 16px;
      .anticon-eye,
      .anticon-eye-invisible {
        color: rgba(0, 0, 0, 0.25);
      }
      &.ant-form-item-with-help {
        margin-bottom: 0;
      }
    }
    :deep(.ant-form-item-label) {
      text-align-last: justify;
      text-align: justify;
      text-justify: distribute-all-lines; // 兼容 IE
    }
    :deep(.ant-input),
    :deep(.ant-input-affix-wrapper),
    :deep(.ant-input-affix-wrapper-focused) {
      line-height: 30px;
      border-radius: 4px;
      //border-width: 0px 0px 1px 0px !important;
      box-shadow: none;
      background-color: #fff;
      &:hover,
      &:focus {
        //border-right-width: 0px !important;
        box-shadow: none;
      }
    }
    .code-img {
      position: absolute;
      right: 0;
      top: 2px;
      right: 1px;
      cursor: pointer;
      :deep(.ant-image-img) {
        width: auto;
        height: 100%;
      }
    }
    .btn {
      margin: 20px 0 10px 0;
      height: 40px;
      font-size: 16px;
    }
    .link {
      font-size: 14px;
      color: #b0bec5;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: @primary-color;
      }
    }
    :deep(.ant-input-clear-icon) {
      margin: 0;
    }
  }
}
.Prompt-font {
  //font-family: 'Arial Normal', 'Arial';
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  letter-spacing: normal;

  color: rgba(49, 61, 95, 0.6);
  font-family: PingFangSC-Semibold;
}
</style>
